<script setup lang="ts">
import { ref } from "vue"

const open = ref(false)
</script>

<template>
  <div class="relative bg-gray-50 overflow-hidden">
    <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
      <div class="relative h-full max-w-screen-xl mx-auto">
        <img
          class="absolute opacity-5 right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
          src="@/assets/XYPN_Grid_Blue-9.svg"
        />
        <img
          class="absolute opacity-10 left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
          src="@/assets/XYPN_Grid_Green-2.svg"
        />
      </div>
    </div>

    <div class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
        <nav
          class="relative flex items-center justify-between sm:h-10 md:justify-center"
        >
          <div
            class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0"
          >
            <div class="flex items-center justify-between w-full md:w-auto">
              <a href="#">
                <img
                  class="h-20 w-auto sm:h-16"
                  src="@/assets/logo-wide.svg"
                  alt=""
                />
              </a>
              <div class="-mr-2 flex items-center md:hidden">
                <button
                  type="button"
                  class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-600 transition duration-150 ease-in-out"
                  @click="open = true"
                >
                  <svg
                    class="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <a
              href="https://www.xyplanningnetwork.com/"
              target="_window"
              class="font-medium text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >About XYPN</a
            >
            <a
              href="/privacy-policy"
              class="ml-10 font-medium text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >Privacy Policy</a
            >
            <a
              href="/terms"
              class="ml-10 font-medium text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >Terms</a
            >
            <a
              href="https://xy-archive.helpscoutdocs.com/"
              target="_window"
              class="ml-10 font-medium text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >Help Desk</a
            >
          </div>
          <div
            class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0"
          >
            <a href="/login" class="xy-btn"> Log in </a>
          </div>
        </nav>
      </div>

      <div
        v-show="open"
        style="display: none"
        class="absolute top-0 inset-x-0 p-2 md:hidden"
      >
        <div
          v-show="open"
          class="rounded-lg shadow-md transition transform origin-top-right"
        >
          <div
            class="rounded-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div class="px-5 pt-4 flex items-center justify-between">
              <div>
                <img class="h-8 w-auto" src="@/assets/logo-wide.svg" alt="" />
              </div>
              <div class="-mr-2">
                <button
                  type="button"
                  class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-600 transition duration-150 ease-in-out"
                  @click="open = false"
                >
                  <svg
                    class="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="px-2 pt-2 pb-3">
              <a
                href="https://www.xyplanningnetwork.com/"
                target="_window"
                class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                >About XYPN</a
              >
              <a
                href="/privacy-policy"
                class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                >Privacy Policy</a
              >
              <a
                href="/terms"
                class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                >Terms</a
              >
              <a
                href="https://xy-archive.helpscoutdocs.com/"
                target="_window"
                class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                >Help Desk</a
              >
            </div>
            <div>
              <a
                href="/login"
                class="block w-full px-5 py-3 text-center font-medium text-xy-blue bg-gray-50 hover:bg-gray-100 hover:text-xy-blue-700 focus:outline-none focus:bg-gray-100 focus:text-xy-blue-700 transition duration-150 ease-in-out"
              >
                Log in
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28"
      >
        <div class="text-center">
          <h2
            class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl"
          >
            XY Archive
            <br />
            <span class="text-xy-blue">Compliance Simplified</span>
          </h2>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            XY Archive is here to make compliance less complicated. We're an
            all-in-one archiving solution for email, website, and social media,
            built for financial advisors.
          </p>
          <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <a
              href="/login"
              class="xy-btn block w-full px-8 py-3 border border-transparent text-base leading-6 font-medium md:py-4 md:text-lg md:px-10"
            >
              Get started
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="py-36 bg-white relative">
      <div class="max-w-screen-xl mx-auto px-12 sm:px-16 lg:px-20">
        <div>
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
          >
            How XY Archive Helps Financial Advisors
          </h3>
        </div>

        <div class="mt-4">
          <ul class="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <li>
              <div class="flex">
                <p class="mt-2 text-base leading-6 text-gray-600">
                  All registered investment advisors (aka, you) are required to
                  keep books and records of any communication and advertising
                  you do in the course of servicing or acquiring clients.
                  Essentially, If you talk to a client or advertise your firm,
                  you have to make a record.
                </p>
              </div>
            </li>
            <li class="mt-10 md:mt-0">
              <div class="flex">
                <p class="mt-2 text-base leading-6 text-gray-600">
                  XY Archive enables you to stay in compliance with books and
                  records requirements (<a
                    target="_window"
                    href="https://www.law.cornell.edu/cfr/text/17/275.204-2"
                    class="xy-link"
                    >SEC Rule 204(2)</a
                  >
                  and
                  <a
                    target="_window"
                    href="https://www.sec.gov/rules/final/34-38245.txt"
                    class="xy-link"
                    >SEC Rule 17a-4</a
                  >) without worrying. Communicate with clients and advertise
                  your business where and when you want—we'll be in the
                  background, archiving away.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="py-36 bg-gray-50 relative">
      <div class="max-w-screen-xl mx-auto px-12 sm:px-16 lg:px-20">
        <div>
          <h3
            class="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 lg:text-center"
          >
            What data we collect — and why
          </h3>
          <p
            class="mt-4 max-w-2xl text-base leading-6 text-gray-600 lg:mx-auto"
          >
            Any data we'll request from you will have one purpose: keeping you
            compliant. To do that, we'll collect and store data from your
            accounts so you don't have to do it manually. You can download these
            communications and attachments into an easily searchable Excel
            spreadsheet. We'll help make your audits easier and your life
            simpler.
          </p>

          <p
            class="mt-4 max-w-2xl text-base leading-6 text-gray-600 lg:mx-auto"
          >
            XY Archive's use and transfer to any other app of information
            received from Google APIs will adhere to
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_window"
              class="xy-link"
            >
              Google API Services User Data Policy</a
            >, including the Limited Use requirements.
          </p>
        </div>

        <div class="lg:grid lg:grid-cols-3 lg:gap-8 mt-36">
          <div class="rounded-lg shadow-lg p-4 bg-white">
            <div class="flex items-center justify-center h-12 w-12 rounded-md">
              <img src="@/assets/art-floppy.svg" alt="Camera" />
            </div>
            <div class="mt-5">
              <h3>Email</h3>
              <p class="mt-2 text-base leading-6 text-gray-600">
                For Gmail and Microsoft email accounts, we use a read-only
                restricted scope to store and reproduce the information you need
                to stay compliant. We store everything you'll need in an
                audit—including the heading, body, sender, recipient, and anyone
                cc'd or bcc'd on your emails.
              </p>
            </div>
          </div>
          <div class="mt-10 lg:mt-0 rounded-lg shadow-lg p-4 bg-white">
            <div class="flex items-center justify-center h-12 w-12 rounded-md">
              <img src="@/assets/art-mapple-map.svg" alt="Camera" />
            </div>
            <div class="mt-5">
              <h3>Website</h3>
              <p class="mt-2 text-base leading-6 text-gray-600">
                Capture your entire website—one page or multiple, we've got it
                covered.
              </p>
            </div>
          </div>
          <div class="mt-10 lg:mt-0 rounded-lg shadow-lg p-4 bg-white">
            <div class="flex items-center justify-center h-12 w-12 rounded-md">
              <img src="@/assets/art-polaroid.svg" alt="Camera" />
            </div>
            <div class="mt-5">
              <h3>Social Media</h3>
              <p class="mt-2 text-base leading-6 text-gray-600">
                The way you advertise your firm and communicate with clients is
                constantly evolving. We'll keep up with you. Promote your
                business on Instagram, Twitter, and Facebook—we'll capture it
                all.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
